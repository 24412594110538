<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <v-select
          v-model="startH"
          data-cy="startHour"
          :items="startHour"
          label="Début de la journée"
        ></v-select>
        <v-select
          v-model="endH"
          data-cy="endHour"
          :items="endHour"
          label="Fin de la journée"
        ></v-select>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { setDayBoundarieCalendarSettings } from '/imports/api/methods/settings';
import log from 'loglevel';
import { useStore } from 'vuex';
import useEmitter from '../../EventBus';

const store = useStore();
const EventBus = useEmitter();
const emit = defineEmits(['close']);

let showEventHours = ref(false);
let startH = ref('');
let endH = ref('');
let startHour = ref([
  { value: '00:00:00', title: '00:00' },
  { value: '01:00:00', title: '01:00' },
  { value: '02:00:00', title: '02:00' },
  { value: '03:00:00', title: '03:00' },
  { value: '04:00:00', title: '04:00' },
  { value: '05:00:00', title: '05:00' },
  { value: '06:00:00', title: '06:00' },
  { value: '07:00:00', title: '07:00' },
  { value: '08:00:00', title: '08:00' },
  { value: '09:00:00', title: '09:00' },
  { value: '10:00:00', title: '10:00' },
  { value: '11:00:00', title: '11:00' },
  { value: '12:00:00', title: '12:00' },
  { value: '13:00:00', title: '13:00' },
  { value: '14:00:00', title: '14:00' },
  { value: '15:00:00', title: '15:00' },
  { value: '16:00:00', title: '16:00' },
  { value: '17:00:00', title: '17:00' },
  { value: '18:00:00', title: '18:00' },
  { value: '19:00:00', title: '19:00' },
  { value: '20:00:00', title: '20:00' },
  { value: '21:00:00', title: '21:00' },
  { value: '22:00:00', title: '22:00' },
  { value: '23:00:00', title: '23:00' }
]);

let endHour = ref([
  { value: '01:00:00', title: '01:00' },
  { value: '02:00:00', title: '02:00' },
  { value: '03:00:00', title: '03:00' },
  { value: '04:00:00', title: '04:00' },
  { value: '05:00:00', title: '05:00' },
  { value: '06:00:00', title: '06:00' },
  { value: '07:00:00', title: '07:00' },
  { value: '08:00:00', title: '08:00' },
  { value: '09:00:00', title: '09:00' },
  { value: '10:00:00', title: '10:00' },
  { value: '11:00:00', title: '11:00' },
  { value: '12:00:00', title: '12:00' },
  { value: '13:00:00', title: '13:00' },
  { value: '14:00:00', title: '14:00' },
  { value: '15:00:00', title: '15:00' },
  { value: '16:00:00', title: '16:00' },
  { value: '17:00:00', title: '17:00' },
  { value: '18:00:00', title: '18:00' },
  { value: '19:00:00', title: '19:00' },
  { value: '20:00:00', title: '20:00' },
  { value: '21:00:00', title: '21:00' },
  { value: '22:00:00', title: '22:00' },
  { value: '23:00:00', title: '23:00' },
  { value: '24:00:00', title: '24:00' }
]);

/* let value = computed({
  get: () => props.shown,
  set: (value) => emit('update:modelValue', value)
}); */

watch(endH, (value, old) => {
  log.info('endH', value, old);
  save();
  //  emit('update:dayBoundaries', { start: startH.value, end: endH.value });
});

watch(startH, (value, old) => {
  log.info('endH', value, old);
  save();
  //  emit('update:dayBoundaries', { start: startH.value, end: endH.value });
});

onMounted(() => {
  log.info('ViewCalendarSettings mounted');
  const settings = store.state.settings;
  startH.value = settings.calendar.dayBoundaries.start;
  endH.value = settings.calendar.dayBoundaries.end;
  showEventHours.value = settings.calendar.event.showEventHours;
});
/**
 * Handles the close button click event.
 * Emits two events:
 * 1. 'update:dayBoundaries' with the start and end hour values.
 * 2. 'update:modelValue' with a boolean value of false.
 */

async function save() {
  await setDayBoundarieCalendarSettings.callAsync({
    start: startH.value,
    end: endH.value
  });
  store.commit('setDayBoundariesCalendarSettings', {
    start: startH.value,
    end: endH.value
  });
  EventBus.emit('update:userCalendarSettings');
  emit('close');
}
</script>

<style></style>
